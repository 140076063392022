
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        












































































.trustpilot-outer {
  margin-top: 1rem;
}

.trustpilot {
  position: relative;
  display: block;
  width: 60%;
  max-width: 14rem;
  margin: 0.5rem auto;
  background-image: linear-gradient(to right, #ee4a3a 50%, gray 50%);
  background-size: 200% 100%;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: (3 / 16) * 100%;
  }

  &.orange {
    background-image: linear-gradient(to right, #ee8b4a 50%, #dcdce7 50%);
  }

  &.yellow {
    background-image: linear-gradient(to right, #f5d04c 50%, #dcdce7 50%);
  }

  &.light-green {
    background-image: linear-gradient(to right, #8cc94a 50%, #dcdce7 50%);
  }

  &.green {
    background-image: linear-gradient(to right, #55b181 50%, #dcdce7 50%);
  }

  svg {
    @include get-all-space;

    width: 100%;
    height: 100%;
    fill: $white;
  }
}

.trustpilot__link {
  display: block;
  width: 10rem;
  margin: 0.8rem auto 0;
}
