
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        








































































































































































































































































































































































































































































































































































































































$bp: l;
$table-gap: 1.5rem;

.comparator,
[class*='comparator--'] {
  margin-top: 9rem;

  @include mq($bp) {
    margin-top: 22rem;
  }
}

.comparator-header {
  margin-bottom: 4rem;
  text-align: center;

  @include mq($bp) {
    margin-bottom: 7rem;
  }
}

.comparator__headline,
.comparator__title {
  display: block;
  margin: 0 0 1rem;
}

.comparator__description {
  color: $c-blue-medium;
}

.comparator__rgus {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  margin-bottom: 4rem;

  @include mq($bp) {
    flex-wrap: nowrap;
    gap: 1.5rem;
  }
}

.comparator__rgu__option {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(50% - 0.5rem);

  .icon {
    width: 100%;
    max-width: 6rem;
    height: 2.5rem;
  }

  @include mq(s) {
    flex-direction: row;

    .icon {
      margin-right: 1rem;
    }

    ::v-deep .selectable-block__check {
      margin: 0 0 0 auto;
    }
  }

  @include mq($bp) {
    flex: 1;
  }
}

.comparator__rgu__option__label {
  @extend %fw-bold;

  margin: 1rem 0;
  font-size: 1.5rem;
  line-height: 1;
}

.comparator-table__error {
  margin-bottom: 4rem;
  padding: 2rem;
  font-size: 1.5rem;
  text-align: center;
  background-color: #fdf3f8;
  border-radius: 1rem;

  b {
    display: block;
    margin-bottom: 0.5rem;
    color: $c-pink-medium;
  }
}

.comparator__subtitle {
  display: block;
  margin-bottom: 2rem;
  text-align: center;

  @include mq($bp) {
    margin-bottom: 4rem;
  }
}

.comparator__tier {
  margin-bottom: 4rem;

  .has-error & {
    opacity: 0.5;
    pointer-events: none;
  }

  @include mq(s) {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  @include mq($bp) {
    gap: 1.5rem;
    margin-bottom: 7rem;
  }
}

.comparator__tier__option {
  display: flex;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ::v-deep .selectable-block__check {
    margin: 0 0 0 auto;
  }

  @include mq(s) {
    max-width: 27rem;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.comparator__tier__option__label {
  @extend %fw-bold;
}

.comparator-table-outer {
  position: relative;
  min-height: 18rem;
}

.comparator-loader {
  @include get-all-space;

  z-index: 10;
  top: -2%;
  bottom: -5%;
  height: auto;
  min-height: 10rem;
  background-color: $white;
  opacity: 0;
  transition: opacity 0.15s;
  pointer-events: none;

  .is-loading & {
    opacity: 1;
  }

  img {
    display: block;
    width: 10rem;
    margin: 0 auto;
  }

  @include mq($until: l) {
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    padding-top: $spacing * 2;
    width: 100vw;
  }
  @include mq(l) {
    transform: scaleX(1.04);
  }
}

.comparator__table {
  position: relative;
  max-width: 100rem;
  margin: 0 auto;

  &.is-full {
    max-width: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  @include mq($until: $bp) {
    display: none;
  }
}

.comparator-promo,
.comparator__table__overlay,
.comparator__table__row {
  display: grid;
  grid-template-columns: 22rem repeat(auto-fit, minmax(10rem, 1fr));
}

.comparator__table__row {
  overflow: hidden;
  list-style-type: none;

  &:nth-child(even) {
    background-color: $c-cultured;
  }

  &:first-child {
    height: 16rem;
    background-color: transparent;
  }

  &:nth-child(2) {
    border-top-left-radius: 3rem;
  }

  &:nth-last-child(2) {
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 3rem;
  }

  &:last-child {
    display: none;
  }

}

.comparator__table__overlay {
  @include get-all-space;

  left: -$table-gap;
  gap: $table-gap;
  pointer-events: none;
}

.comparator__table__row__cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
  text-align: center;

  &:not(:last-child):not(:first-child) {
    padding-right: calc(#{$table-gap} + 1rem);
  }

  .comparator__table__row:not(:first-child):nth-child(even) &:first-child {
    background-color: $c-gallery;
  }

  .comparator__table__row:not(:first-child):nth-child(odd) &:first-child {
    background-color: $c-cultured;
  }

  @include mq($bp) {
    padding: $spacing * 2 $spacing $spacing;

    &:not(:last-child):not(:first-child) {
      padding-right: calc(#{$table-gap} + 2rem);
    }
  }
}

.comparator-table-cell-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  ::v-deep .wysiwyg {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &.is-logo {
    max-height: $spacing * 0.25;
    margin-bottom: $spacing;

    @include mq($bp) {
      max-height: $spacing * 2.5;
    }
  }

  &:not(.is-trustpilot) {
    ::v-deep img {
      @include image-fit(contain);
      @include center-xy;

      max-width: 18rem;
      // max-height: 5rem;
    }
  }

  ::v-deep .headline {
    @include center-x;

    top: -2.2rem;
  }

  ::v-deep .title {
    @extend %fw-bold;

    color: $black;
    font-family: $ff-alt;
    font-size: 2.2rem;
    line-height: 1.4;

    i {
      font-size: 1.5rem;
      font-style: normal;
    }

    .comparator__table__row__cell:nth-child(2) & {
      color: $c-pink-medium;
    }

    @include mq($bp) {
      font-size: 3.2rem;

      i {
        font-size: 1.8rem;
      }
    }
  }

  ::v-deep .subtitle,
  ::v-deep .headline {
    @extend %fw-light;

    color: $c-blue-medium;
    font-size: 1.3rem;

    @include mq($bp) {
      font-size: 1.5rem;
    }
  }

  ::v-deep .link--pink,
  ::v-deep .overlayer-trigger {
    @extend %fw-light;

    display: initial !important;
    font-family: $ff-default;
    font-size: 1.5rem;
    background-size: 100% 1px, 0 1px;

    span {
      display: initial !important;
      background-size: 100% 1px, 0 1px;

      &:hover,
      &:focus {
        color: $c-blue-dark;
        background-position: 100% 100%, 0 100%;
        background-size: 0 1px, 100% 1px;
      }
    }
  }

  ::v-deep .tooltip {
    position: relative;
    margin-top: $spacing;
    padding: 1rem;
    color: $c-blue-darkest;
    font-size: 1.3rem;
    border: 1px solid $c-weldon-blue;
    border-radius: 1rem;

    &::before {
      @include center-x;

      content: '';
      top: 0;
      width: 2.5rem;
      height: 2.5rem;
      background-color: $white;
      border: solid $c-weldon-blue;
      border-width: 1px 0 0 1px;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .comparator__mobile-table__row:not(:first-child):nth-child(odd) &,
    .comparator__table__row:not(:first-child):nth-child(even) & {
      &::before {
        background-color: $c-cultured;
      }
    }

    @include mq(s) {
      padding: $spacing * 0.6 $spacing;
      font-size: 1.5rem;
    }
  }
}

.comparator__table__row__cell__icon {
  max-width: 6rem;
  height: 4rem;
  margin-bottom: 1.5rem;
}

.comparator__table__overlay__item {
  &:not(:first-child) {
    border-radius: 2rem;
    box-shadow: 0 0 20px rgba($black, 0.2);
  }

  &:nth-child(2) {
    border-bottom-left-radius: 0;
  }

  &:last-child {
    margin-right: -$table-gap;
  }
}

.comparator__mobile-table {
  ul {
    margin: 0;
    padding: 0;
  }

  @include mq(xxs, m) {
    margin-right: -2rem;
    margin-left: -2rem;
  }

  @include mq(m, $bp) {
    margin-right: -7rem;
    margin-left: -7rem;
  }

  @include mq($bp) {
    display: none;
  }
}

.comparator-sale__label {
  margin-bottom: $spacing;
}

.comparator-sale__title {
  margin-bottom: $spacing;
}

.comparator__mobile-table-outer {
  position: sticky;
  z-index: 10;
  top: 0;
  transition: top 0.4s;

  .header-open & {
    top: 4.5rem;
  }
}

.comparator__mobile-table__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  height: 0;
  background-color: $white;
  transition: height 0.5s;

  .is-sticky & {
    height: 6rem;
  }
}

.comparator__mobile-table__controls__edit {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.comparator__mobile-table__header {
  display: flex;
  align-items: center;
  height: 7rem;
  background-color: $white;
  box-shadow: 0 5px 8px 0 rgba(3, 14, 27, 0.1);
}

.comparator__mobile-table__header__toggle {
  @include center-y;

  right: 2rem;
  width: 1rem;
  height: 1rem;
  transition: transform 0.25s;

  .is-open & {
    transform: translateY(-50%) scale(-1);
  }
}

.comparator__mobile-table__header__item {
  position: relative;
  width: 50%;
  height: 80%;
  padding: 0;
  text-align: center;
  background-color: transparent;
  border: 0;

  ::v-deep img {
    height: 2.5rem !important;
  }
}

.comparator__mobile-table__header__list {
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 5rem;
  background-color: $white;
  box-shadow: 0 5px 8px 0 rgba(3, 14, 27, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.15s;

  .is-open & {
    opacity: 1;
    pointer-events: auto;
  }
}

.comparator__mobile-table__row {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 2rem;

  &:nth-child(odd) {
    background-color: $c-cultured;
  }

  &:last-child {
    display: none;
  }
}

.comparator__mobile-table__row__label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 2rem;
  font-family: $ff-alt;
  font-size: 1.8rem;

  svg {
    width: 100%;
    max-width: 3.5rem;
    height: 100%;
    max-height: 3rem;
  }
}

.comparator__mobile-table__row__cell {
  width: 50%;
  text-align: center;

  &:nth-child(2) {
    padding-right: 1rem;
    border-right: 1px solid $c-blue-light-pale;
  }

  &:last-child {
    padding-left: 1rem;
  }
}

.comparator-promo {
  margin-top: 4rem;

  &:not(&.is-full) {
    max-width: 100rem;
    margin: 4rem auto 0;
  }

  @include mq($until: $bp) {
    display: block;
  }
}

.comparator-sale {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1rem;
  padding: 2rem;
  color: $white;
  background-color: $c-bunting;
  border-radius: 1rem;

  &::before {
    @include center-x;

    content: '';
    z-index: -1;
    top: 0;
    left: 20%;
    width: 2.7rem;
    height: 2.7rem;
    background-color: $c-bunting;
    transform: translate(0, -50%) rotate(45deg);
  }

  @include mq($bp) {
    &::before {
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.comparator__message {
  max-width: 100rem;
  margin: 4rem auto 0;
  text-align: center;

  ::v-deep a {
    color: $c-pink-medium;
    text-decoration: none;
  }

  @include mq($bp) {
    margin: 7rem auto 8rem;
  }
}

.comparator__dualcard {
  padding-right: 0;
  padding-left: 0;
}

.comparator__faq {
  ::v-deep .faq {
    padding-right: 0;
    padding-left: 0;
  }

  ::v-deep .faq__item {
    border: 0;
  }
}
